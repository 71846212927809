import React, { useState, useEffect } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link } from "gatsby"
import "./technical-assistance.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faGlobe,
  faEnvelope,
  faUser,
  faPhoneVolume,
  faSearch,
  faStickyNote
} from "@fortawesome/free-solid-svg-icons"
import Tech from "../data/technologyMax"
import { faAccessibleIcon } from "@fortawesome/free-brands-svg-icons"

const TechMax = () => {
  const [tech, setTech] = useState(
    Tech.partner
  )
  const [filteredList, setFilterList] = useState([])
  const [searchTerm, setSearchTerm] = useState("")
  const handleChange = event => {
    setSearchTerm(event.target.value)
  }

  useEffect(() => {
    const filterTech = tech.filter(word =>
      word.keywords.some(subWords =>
        subWords
          .toString()
          .toLowerCase()
          .includes(searchTerm.toLowerCase())
      )
    )
    setFilterList(filterTech)
  }, [searchTerm])

  return (
    <Layout>
      <SEO title="Technology Maximization" />
      <div className="container primary-bg">
      <div>
          <Link className="additonalFundingHeader" to="/business-assistance">
            Business Technical Assistance
          </Link>
        </div>
        <div>
          <label className="additonalFundingTitle">
           Technology Maximization
          </label>
        </div>
        <hr />
        <section className="container">
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <FontAwesomeIcon
                  className="fontIcon"
                  size="lg"
                  icon={faSearch}
                />
              </span>
            </div>
            <input
              type="text"
              placeholder="Search by Keyword (e.g. CARES Act)"
              value={searchTerm}
              onChange={handleChange}
              className="form-control inputText"
            />
          </div>
        </section>
        <section className="container">
          {filteredList
            .sort((a, b) => (a.orgName > b.orgName ? 1 : -1))
            .map((key, index) => {
              return (
                <div>
                  <label className="additonalFundingCardTitle">
                    {key.orgName}
                  </label>
                  <div className="rowSpace" hidden={key.link == ""}>
                    <FontAwesomeIcon
                      className="iconStyle"
                      size="lg"
                      icon={faGlobe}
                    />
                    <a className="techLinks" href={key.link} target="_blank">
                      {key.link}
                    </a>
                  </div>
                  <div className="rowSpace" hidden={key.name == ""}>
                    <FontAwesomeIcon
                      className="iconStyle"
                      size="lg"
                      icon={faUser}
                    />
                    <label className="nameLink">{key.name}</label>
                  </div>
                  <div className="rowSpace" hidden={key.email == ""}>
                    <FontAwesomeIcon
                      className="iconStyle"
                      size="lg"
                      icon={faEnvelope}
                    />
                    <a className="techLinks" href="mailto:" {...key.email}>
                      {key.email}
                    </a>
                  </div>
                  <div className="rowSpace" hidden={key.phone == ""}>
                    <FontAwesomeIcon
                      size="lg"
                      className="iconStyle phoneIcon"
                      icon={faPhoneVolume}
                    />
                    <label className="nameLink">{key.phone}</label>
                  </div>
                  <div className="rowSpace" hidden={key.comments == ""}>
                    <FontAwesomeIcon
                      size="lg"
                      className="iconStyle"
                      icon={faStickyNote}
                    />
                    <label className="commentText">{key.comments}</label>
                  </div>
                  <hr />
                </div>
              )
            })}
        </section>
      </div>
    </Layout>
  )
}

export default TechMax
