export const TechnologyMax = {
    partner: [
      {
        "orgName": "SBDC at FIU- Small Business Development Center at FIU",
        "link": "https://business.fiu.edu/centers/sbdc/",
        "name":"Shelly Bernal",
        "email":"shelly.bernal@floridasbdc.org",
        "phone": "",
        "comments":"",
        "keywords":["Social Media", "e-Commerce", "Website Design", "Technology", "Operations", "Disaster Capital", "Rebuild", "Recovery", "Growth", "Small Business Resources", "Strategic Plan"]
      },
      {
        "orgName": "General Assembly",
        "link": "https://generalassemb.ly/",
        "name":"Carie Perez",
        "email":"cari.perez@generalassemb.ly",
        "phone": "",
        "comments":"",
        "keywords":["Social Media", "e-Commerce", "Website Design", "Operations", "Web Development", "Design", "Data Management", "Coding", "Code", "Technology", "Maximization", "Scale"]
      },
//       {
//         "orgName": "ActionCoach Team Sage",
//         "link": "https://actioncoachteamsage.com/staff-directory/jody-johnson/",
//         "name":"Jody A. Johnson",
//         "email":"jodyjohnson@actioncoach.com",
//         "phone": "",
//         "comments":"",
//         "keywords":["Operations", "Strategic Planning", "Assesment", "Growth", "Strategy", "Maximization", "Scale"]
//       },
        {
        "orgName": "SEEED",
        "link": "https://seeed.us/",
        "name":"Rowland Sear",
        "email":"rsaer@seeed.us",
        "phone": "",
        "comments":"",
        "keywords":["APP Development", "e-Commerce", "Inventory", "POS", "Micro Transactions", "Digital Transformation", "DevOps"]
      },
      {
        "orgName": "South Florida Digital Alliance",
        "link": "http://sfdigitalalliance.org/",
        "name":"Don Slesnick III",
        "email":"don@sfdigitalalliance.org",
        "phone": "",
        "comments":"",
        "keywords":["e-Commerce", "Website Design", "Operations", "Digital Literacy"]
      },
        {
        "orgName": "Neo Broadband",
        "link": "https://neobroadband.net/about-us/",
        "name":"Edmerson Vasquez",
        "email":"edmerson.vasquez@neobroadband.tv",
        "phone": "",
        "comments":"",
        "keywords":["inventory", "computer", "refurbished equipment", "modems"]
      },
      {
        "orgName": "Prospera",
        "link": "https://prosperausa.org/",
        "name":"Myrna Sonora",
        "email":"msonora@prosperausa.org",
        "phone": "",
        "comments":"",
        "keywords":["e-commerce", "Branding", "Distribution", "Hispanic Small Business", "Training", "Consulting", "Grants", "Access to Capital", "Spanish", "International", "Immigration"]
      },
        {
        "orgName": "SCORE Miami Dade",
        "link": "https://miamidade.score.org/",
        "name":"",
        "email":"score.bizhelp.miami@scorevolunteer.org",
        "phone": "",
        "comments":"",
        "keywords":["e-commerce", "Branding", "Distribution", "Training", "Grants", "Access to Capital", "Spanish"]
      },
        {
        "orgName": "WeWork Labs Miami",
        "link": "https://www.weworklabs.com/labs/",
        "name": "Pedro Sostre",
        "email": "pedro.sostre@wework.com",
        "phone": "",
        "comments":"",
        "keywords": ["Office Space", "Collaborative", "Coworking", "Leasing", "E-commerce"]
      },
    ],
  }
  
  export default TechnologyMax
  
